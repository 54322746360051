import React from 'react'
import { Image } from 'react-bootstrap'
import { getCorrectImage } from './helpers'

interface HeaderProps {
  img?: string
}

const Header = ({ img }: HeaderProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem'
      }}
    >
      <Image
        src={getCorrectImage(img)}
        style={{ height: 'auto', width: 320, maxWidth: '70%' }}
        className="mb-2"
      />
    </div>
  )
}

export default Header

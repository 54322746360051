import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './index.scss'

interface ButtonProps {
  text: string
  onClick: () => void
}

interface CustomModalProps {
  show: boolean
  title?: string
  message?: string
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps
}

const CustomModal = ({
  show,
  title,
  message,
  cancelButtonProps,
  confirmButtonProps
}: CustomModalProps) => (
  <Modal
    show={show}
    onHide={cancelButtonProps?.onClick || confirmButtonProps?.onClick}
    centered
  >
    {title && (
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
    )}
    {message && <Modal.Body>{message}</Modal.Body>}
    <Modal.Footer>
      {cancelButtonProps && (
        <Button variant="secondary" onClick={cancelButtonProps?.onClick}>
          {cancelButtonProps.text}
        </Button>
      )}
      {confirmButtonProps && (
        <Button
          variant="primary"
          className="text-white"
          onClick={confirmButtonProps?.onClick}
        >
          {confirmButtonProps.text}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
)

export default CustomModal

import {
  Container,
  Divider,
  ExternalAuthButtons,
  Header,
  Root,
  SignInLink,
  Title
} from 'components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Route from 'routes/routes'
import RegisterForm from './form'

const blockedHostnames = ['olanota.com']

const Register = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'register' })
  const history = useHistory()
  const isBlocked = blockedHostnames.some(d =>
    window.location.hostname.includes(d)
  )

  useEffect(() => {
    if (isBlocked) {
      history.push({
        pathname: Route.REGISTER_BLOCKED
      })
    }
  }, [isBlocked])

  return (
    <Root>
      <Header />
      <Container>
        <Title text={t('title')} />
        <RegisterForm />
        <Divider />
        <ExternalAuthButtons />
        <SignInLink />
      </Container>
    </Root>
  )
}

export default Register

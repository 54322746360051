import { Container, Header, Root, Title } from 'components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import UnocartSigninForm from './form'

const UnocartSignin = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signin' })

  return (
    <Root backgroundColor="#fff">
      <Header img="unocart" />
      <Container style={{ maxWidth: 500 }}>
        <Title text={t('title')} />
        <UnocartSigninForm />
      </Container>
    </Root>
  )
}

export default UnocartSignin

export const getDomain = () => {
  const { REACT_APP_MOCK_DOMAIN, NODE_ENV } = process.env

  if (NODE_ENV !== 'production') return REACT_APP_MOCK_DOMAIN || 'receiptjar'

  const hostname = window.location.hostname || document.location.hostname
  const hostnameComponents = hostname.split('.')
  if (hostnameComponents?.[1] === 'auth') {
    return hostnameComponents?.[2]
  }
  return hostnameComponents?.[1]
}

export const getDetectedLanguage = () => localStorage.getItem('i18nextLng')

type appIdentifierType = {
  androidId?: string | null
  idfv?: string | null
}
export const getAppIdentifier = (): appIdentifierType => ({
  idfv: localStorage.getItem('idfv'),
  androidId: localStorage.getItem('androidId')
})

export const getUserTimeZone = () =>
  localStorage.getItem('timezone') ||
  Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone

export const getUserTimeOffset = () => new Date().getTimezoneOffset()

export const isWebview = () => {
  // App injection
  // @ts-ignore
  if (window?.isUIWebview) {
    return true
  }

  if (!window) {
    return false
  }

  const userAgent = window.navigator.userAgent.toLowerCase()
  const isIOSUIWebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
    userAgent
  )
  const isAndroidUIWebview = /wv/i.test(userAgent)

  return isAndroidUIWebview || isIOSUIWebview
}

import React, { useRef } from 'react'
import { siteKey } from 'assets/constants'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReCaptchaCheckBox from './checkbox'

interface ReCaptchaDialogProps {
  show: boolean
  handleClose: () => void
  handleSuccess: (t) => void
}

const ReCaptchaDialog = ({
  show,
  handleClose,
  handleSuccess
}: ReCaptchaDialogProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'reCaptchaDialog' })

  const recaptchaRef = useRef(null)

  const handleChange = async () => {
    const token = await recaptchaRef.current.getValue()
    handleSuccess(token)
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReCaptchaCheckBox
          style={{ display: 'flex', justifyContent: 'center' }}
          ref={recaptchaRef}
          sitekey={siteKey}
          onChange={handleChange}
        />
      </Modal.Body>
    </Modal>
  )
}

export default ReCaptchaDialog

import { Container, Header, Root } from 'components'
import React from 'react'

const Callback = () => {
  return (
    <Root>
      <Header />
      <Container>
        <div data-testid="Callback" />
      </Container>
    </Root>
  )
}

export default Callback

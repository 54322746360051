import CustomModal from 'components/modal'
import { isKnownError } from 'pages/helpers'
import * as React from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useVerifyAuthCode } from './hooks'

const MFACodeForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'mfaCode' })

  const [handleVerify, errorMessage, setErrorMessage] = useVerifyAuthCode()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async ({ code }) => handleVerify(code)

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="verificationCode">
        <Controller
          control={control}
          name="code"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('errorMessages.code.required')
            }
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.code}
              placeholder={t('placeholders.code')}
              size="lg"
              autoCapitalize="off"
              autoCorrect="none"
              inputMode="numeric"
            />
          )}
        />
        <Form.Control.Feedback type="invalid" data-testid="MFACode.Errors">
          {errors.code?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Controller
        name="submitButton"
        control={control}
        render={({ formState }) => (
          <Button
            type="submit"
            variant="primary"
            size="lg"
            style={{
              width: '100%',
              color: '#fff'
            }}
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span className="spinner-border spinner-border-sm mr-1" />
            ) : (
              t('submitButton')
            )}
          </Button>
        )}
      />
      <CustomModal
        show={!!errorMessage.length}
        title={
          isKnownError(errorMessage)
            ? t(`errorTitle.${errorMessage}`)
            : t('errorTitle.defaultErrorTitle')
        }
        message={errorMessage}
        confirmButtonProps={{
          text: t('alert.confirmButton'),
          onClick: () => setErrorMessage('')
        }}
      />
    </Form>
  )
}

export default MFACodeForm

import { potatoAccounts, siteKey } from 'assets/constants'
import { ReCaptchaDialog } from 'components'
import CustomModal from 'components/modal'
import { isKnownError } from 'pages/helpers'
import React, { SyntheticEvent } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Route from 'routes/routes'
import { loginPatternRule } from 'services/form-validation'
import { useEmailLogin } from './hooks'

const LoginForm = () => {
  const history = useHistory()
  const { t } = useTranslation('translation', { keyPrefix: 'signin' })
  const [isLoading, handleLogin, errorMessage, setErrorMessage] =
    useEmailLogin()
  const [showReCaptcha, setShowReCaptcha] = React.useState<boolean>(false)

  const {
    control,
    formState: { errors },
    getValues,
    trigger
  } = useForm()

  const handleSubmitForm = async (e: SyntheticEvent) => {
    e.preventDefault()

    const isValid = await trigger()
    if (!isValid) return

    const { email, password } = getValues()
    if (potatoAccounts.includes(email)) {
      return handleLogin({
        username: email.trim(),
        password,
        siteKey,
        token: 'eopjfsfdf0942834#%$%n4kl535DFsldfklsdW$L54l5#%$'
      })
    }

    setShowReCaptcha(true)
  }

  const handleReCaptchaSuccess = (token: string) => {
    setShowReCaptcha(false)
    const { email, password } = getValues()
    return handleLogin({ username: email.trim(), password, siteKey, token })
  }

  const handleForgotPasswordClick = () => history.push(Route.RESET_PASSWORD)

  return (
    <Form noValidate onSubmit={handleSubmitForm}>
      <Form.Group className="mb-3" controlId="loginEmail">
        <Controller
          control={control}
          name="email"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('errorMessages.email.required')
            },
            pattern: {
              value: loginPatternRule,
              message: t('errorMessages.email.pattern')
            }
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.email}
              placeholder={t('placeholders.email')}
              size="lg"
              autoCapitalize="off"
              autoCorrect="none"
            />
          )}
        />
        <Form.Control.Feedback type="invalid" data-testid="Login.Email.Errors">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="loginPassword">
        <Controller
          control={control}
          name="password"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('errorMessages.password.required')
            },
            minLength: {
              value: 8,
              message: t('errorMessages.password.minLength')
            },
            maxLength: {
              value: 100,
              message: t('errorMessages.password.maxLength')
            },
            pattern: {
              value: /(?=.*\d)(?=.*[a-zA-Z])/,
              message: t('errorMessages.password.pattern')
            }
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.password}
              placeholder={t('placeholders.password')}
              size="lg"
              type="password"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="Login.Password.Errors"
        >
          {errors.password?.message}
        </Form.Control.Feedback>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Form.Text
            className="link-primary text-bold"
            onClick={handleForgotPasswordClick}
          >
            {t('forgotPassword')}
          </Form.Text>
        </div>
      </Form.Group>
      <Controller
        name="submitButton"
        control={control}
        render={({ formState }) => (
          <Button
            type="submit"
            variant="primary"
            size="lg"
            style={{
              width: '100%',
              color: '#fff'
            }}
            disabled={formState.isSubmitting || isLoading}
          >
            {formState.isSubmitting || isLoading ? (
              <span className="spinner-border spinner-border-sm mr-1" />
            ) : (
              t('submitButton')
            )}
          </Button>
        )}
      />
      <ReCaptchaDialog
        show={showReCaptcha}
        handleClose={() => setShowReCaptcha(false)}
        handleSuccess={handleReCaptchaSuccess}
      />
      <CustomModal
        show={!!errorMessage.length}
        title={
          isKnownError(errorMessage)
            ? t(`errorTitle.${errorMessage}`)
            : t('errorTitle.defaultErrorTitle')
        }
        message={errorMessage}
        confirmButtonProps={{
          text: t('alert.confirmButton'),
          onClick: () => setErrorMessage('')
        }}
      />
    </Form>
  )
}

export default LoginForm

import * as configcat from 'configcat-js'
import { PollingMode } from 'configcat-js'
import config from 'services/config'

// Must set up a default user to be by targeted % user
const configCatClient = configcat.getClient(
  config.configCat,
  PollingMode.AutoPoll,
  {
    defaultUser: new configcat.User(
      'auth-frontend',
      'auth-frontend',
      'auth-frontend'
    )
  }
)

enum FeatureFlag {
  useAccountApiV2 = 'useAccountApiV2'
}

export { FeatureFlag, configCatClient }

const releaseChannel = process.env.REACT_APP_ENV

const getConfig = (channel?: string): Config => {
  switch (channel) {
    case 'production':
      return {
        environment: 'production',
        baseUrl: 'https://asia.api.data-123.com',
        sentryDsn:
          'https://2ca7a227ca6b42d38ca0995739cd91b9@o78584.ingest.sentry.io/6158149',
        accountApiUrl: 'https://production.api.unocart.com/account',
        accountApiUrlV2: 'https://api2.datatwix.com/account',
        apiUrl: 'https://api.data-123.com',
        configCat: 'KqLbCOUqyUmEf2GE0Etg_g/FTWuQBLyKUGzE6GHKny0iA'
      }
    default:
      return {
        environment: channel,
        baseUrl: 'https://asia.api.data-123.com',
        accountApiUrl: 'https://production.api.unocart.com/account',
        accountApiUrlV2: 'https://api2.staging.datatwix.com/account',
        apiUrl: 'https://api.data-123.com',
        configCat: 'KqLbCOUqyUmEf2GE0Etg_g/GKnjpkqgMUybTLQTvqclbg'
      }
  }
}

export interface Config {
  baseUrl: string
  environment?: string
  sentryDsn?: string
  accountApiUrl?: string
  accountApiUrlV2?: string
  apiUrl?: string
  configCat: string
}

const config = getConfig(releaseChannel)

export default config

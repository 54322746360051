import CustomModal from 'components/modal'
import { isKnownError } from 'pages/helpers'
import * as React from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { emailPatternRule } from 'services/form-validation'
import useHandleResetPassword from './hooks'

const ResetPasswordForm = () => {
  const history = useHistory()
  const { t } = useTranslation('translation', { keyPrefix: 'resetPassword' })
  const [isLoading, handleResetPassword, settleMessage, setSettleMessage] =
    useHandleResetPassword()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async data => handleResetPassword(data)

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="loginEmail">
        <Controller
          control={control}
          name="email"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('errorMessages.email.required')
            },
            pattern: {
              value: emailPatternRule,
              message: t('errorMessages.email.pattern')
            }
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.email}
              placeholder={t('placeholders.email')}
              size="lg"
              autoCapitalize="off"
              autoCorrect="none"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="ResetPassword.Email.Errors"
        >
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Controller
        name="submitButton"
        control={control}
        render={({ formState }) => (
          <Button
            type="submit"
            variant={'primary'}
            size="lg"
            style={{
              width: '100%',
              color: '#fff'
            }}
            disabled={formState.isSubmitting || isLoading}
          >
            {formState.isSubmitting || isLoading ? (
              <span className="spinner-border spinner-border-sm mr-1" />
            ) : (
              t('submitButton')
            )}
          </Button>
        )}
      />
      <CustomModal
        show={!!settleMessage.length}
        title={
          isKnownError(settleMessage)
            ? t(`errorTitle.${settleMessage}`)
            : t('alert.successTitle')
        }
        message={settleMessage}
        confirmButtonProps={{
          text: t('alert.confirmButton'),
          onClick: () => {
            setSettleMessage(prev => {
              prev === t('alert.success') && history.goBack()
              return ''
            })
          }
        }}
      />
    </Form>
  )
}

export default ResetPasswordForm

import { BlockedAssets } from 'assets/blocked'
import { Title } from 'components'
import React from 'react'
import { Button, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Route from 'routes/routes'

const InvalidPasswordResetToken = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'passwordReset.invalidToken'
  })
  const history = useHistory()

  const handleTryAgainClick = () => {
    history.push(Route.RESET_PASSWORD)
  }

  return (
    <div>
      <Title text={t('title')} />
      <div className="d-flex justify-content-center">
        <Image
          src={BlockedAssets.PasswordReset}
          style={{
            height: 'auto',
            width: 150,
            marginTop: 16,
            marginBottom: 32
          }}
        />
      </div>
      <p className="text-center">{t('message')}</p>
      <Button
        variant={'primary'}
        size="lg"
        className="w-100 text-white"
        onClick={handleTryAgainClick}
      >
        {t('tryAgain')}
      </Button>
    </div>
  )
}

export default InvalidPasswordResetToken

import React from 'react'
import {
  Container,
  Header,
  Root,
  Title,
  RegisterLink,
  ExternalAuthButtons,
  Divider
} from 'components'
import { useTranslation } from 'react-i18next'
import LoginForm from './form'

const Login = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signin' })

  return (
    <Root>
      <Header />
      <Container>
        <Title text={t('title')} />
        <LoginForm />
        <Divider />
        <ExternalAuthButtons />
        <RegisterLink />
      </Container>
    </Root>
  )
}

export default Login

import React from 'react'
import {
  Container,
  Title,
  Header,
  Root,
  RegisterLink,
  SignInLink
} from 'components'
import { useTranslation } from 'react-i18next'
import ResetPasswordForm from './form'

const ResetPassword = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'resetPassword' })

  return (
    <Root>
      <Header />
      <Container>
        <Title text={t('title')} subText={t('subtitle')} />
        <ResetPasswordForm />
        <SignInLink />
        <RegisterLink />
      </Container>
    </Root>
  )
}

export default ResetPassword

import React from 'react'
import Button from './button'

const ExternalAuthButtons = () => {
  const iOS: boolean = /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <>
      <Button variant="facebook" />
      {iOS && (
        <>
          <p />
          <Button variant="apple" />
        </>
      )}
    </>
  )
}

export default ExternalAuthButtons

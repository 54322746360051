import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { History } from 'history'
import config from 'services/config'

const initSentry = (history: History<any>) => {
  if (config.sentryDsn) {
    Sentry.init({
      ignoreErrors: [],
      dsn: config.sentryDsn,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
      ],
      tracesSampler(samplingContext: any): number | boolean {
        if (samplingContext.location.pathname === '/') {
          return 0
        }
        return 1.0
      }
    })
  }
}
export default initSentry

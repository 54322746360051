import { useQuery } from 'react-query'
import { AuthApi } from 'services/api'
import { IValidTokenRes } from 'services/api/auth'
import { queryKeys } from 'services/api/query-keys'
import { useQueryParams } from 'services/custom-hooks'

export const useRegisterPasswordResetToken = (): [
  string | undefined,
  IValidTokenRes,
  boolean
] => {
  const query = useQueryParams()
  const token = query.get('token')

  const { data, isLoading, isFetching } = useQuery(
    [queryKeys.VALIDATE_PASSWORD_RESET_TOKEN, token],
    () => AuthApi.validatePasswordResetToken(token),
    { enabled: !!token, refetchOnWindowFocus: false }
  )

  return [token, data, isLoading || isFetching]
}

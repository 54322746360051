import React from 'react'
import { Container as BootstrapContainer } from 'react-bootstrap'

interface ContainerProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const Container = ({ children, style }: ContainerProps) => {
  return (
    <BootstrapContainer
      className="bg-white pb-4 h-100 d-flex flex-column flex-grow-1"
      style={{ borderRadius: '16px 16px 0 0', ...style }}
    >
      {children}
    </BootstrapContainer>
  )
}

export default Container

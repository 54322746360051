import { Title } from 'components'
import CustomModal from 'components/modal'
import { escapeRegExp } from 'lodash'
import React, { SyntheticEvent, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import Route from 'routes/routes'
import { AuthApi } from 'services/api'
import { customValidator, FormValidationSchema } from 'services/form-validation'
import { isWebview } from 'services/helpers'

interface Props {
  token: string
  variant?: 'primary' | 'unocart'
}

const PasswordResetForm = ({ token, variant = 'primary' }: Props) => {
  const isUIWebview = isWebview()

  const { t } = useTranslation('translation', {
    keyPrefix: 'passwordReset.form'
  })
  const { t: t2 } = useTranslation('translation')

  const history = useHistory()

  const newPassword = useRef<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const defaultSettleMessage = { title: '', message: '' }
  const [onSettleMessage, setOnSettleMessage] = useState(defaultSettleMessage)

  const { mutateAsync } = useMutation(AuthApi.passwordReset, {
    onSuccess: data => {
      const { result } = data
      setOnSettleMessage({
        title: t(`alert.${result}.title`),
        message: t(`alert.${result}.message`)
      })
    },
    onError: error => {
      setIsLoading(false)
      console.error('While logging in....', error)
      setOnSettleMessage({
        title: t('alert.failed.title'),
        message: t('alert.failed.message')
      })
    }
  })

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    trigger
  } = useForm({
    resolver: data => {
      const errors = customValidator(validationSchema, data)
      return {
        values: data,
        errors
      }
    }
  })

  newPassword.current = watch('newPassword', '')

  const validationSchema: FormValidationSchema = {
    newPassword: {
      pattern: [
        {
          value: /(?=.*\d)(?=.*[a-zA-Z])/,
          message: t2('register.errorMessages.password.pattern')
        }
      ],
      required: {
        value: true,
        message: t2('register.errorMessages.password.required')
      },
      minLength: {
        value: 8,
        message: t2('register.errorMessages.password.minLength')
      },
      maxLength: {
        value: 100,
        message: t2('register.errorMessages.password.maxLength')
      }
    },
    repeatNewPassword: {
      pattern: [
        {
          value: new RegExp(`^${escapeRegExp(newPassword.current)}$`),
          message: t2('register.errorMessages.confirmPassword.pattern')
        }
      ],
      required: {
        value: true,
        message: t2('register.errorMessages.confirmPassword.required')
      }
    }
  }

  const handleSubmitForm = async (e: SyntheticEvent) => {
    e.preventDefault()

    const isValid = await trigger()
    if (!isValid) return

    const { newPassword, repeatNewPassword } = getValues()

    setIsLoading(true)
    await mutateAsync({
      token,
      newPassword,
      repeatNewPassword
    })
    setIsLoading(false)
  }

  return (
    <div>
      <Title text={t('title')} subText={t('subTitle')} />
      <Form noValidate onSubmit={handleSubmitForm}>
        <Form.Group className="mb-3" controlId="newPassword">
          <Controller
            control={control}
            name="newPassword"
            defaultValue=""
            render={({ field: { onChange, value, ref } }) => (
              <Form.Control
                onChange={onChange}
                value={value}
                ref={ref}
                isInvalid={errors.newPassword}
                placeholder={t('placeholder.newPassword')}
                size="lg"
                type="password"
              />
            )}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="repeatNewPassword">
          <Controller
            control={control}
            name="repeatNewPassword"
            defaultValue=""
            render={({ field: { onChange, value, ref } }) => (
              <Form.Control
                onChange={onChange}
                value={value}
                ref={ref}
                isInvalid={errors.newPassword || errors.repeatNewPassword}
                placeholder={t('placeholder.repeatNewPassword')}
                size="lg"
                type="password"
              />
            )}
          />
          <Form.Control.Feedback
            type="invalid"
            data-testid="PasswordReset.Errors"
          >
            {errors.newPassword?.message || errors.repeatNewPassword?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Controller
          name="submitButton"
          control={control}
          render={({ formState }) => (
            <Button
              type="submit"
              variant={variant}
              size="lg"
              style={{
                width: '100%',
                color: '#fff'
              }}
              disabled={formState.isSubmitting || isLoading}
            >
              {formState.isSubmitting || isLoading ? (
                <span className="spinner-border spinner-border-sm mr-1" />
              ) : (
                t('submitButton')
              )}
            </Button>
          )}
        />
        <CustomModal
          show={!!onSettleMessage.title || !!onSettleMessage.message}
          title={onSettleMessage.title}
          message={onSettleMessage.message}
          confirmButtonProps={{
            text: t('alert.confirmButton'),
            onClick: () => {
              setOnSettleMessage(prev => {
                // navigate to login route
                if (prev.title === t('alert.ok.title') && isUIWebview) {
                  history.replace({
                    pathname: Route.LOGIN
                  })
                }
                return defaultSettleMessage
              })
            }
          }}
        />
      </Form>
    </div>
  )
}

export default PasswordResetForm

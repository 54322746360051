import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'

export const useQueryParams = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const useRegisterAppIdentifier = () => {
  const query = useQueryParams()

  useEffect(() => {
    const idfv = query.get('idfv')
    const androidId = query.get('androidId')

    // intentionally override existing keys when query changed
    if (idfv || androidId) {
      localStorage.setItem('androidId', androidId)
      localStorage.setItem('idfv', idfv)
    }
  }, [query])
}

export const useRegisterAppTimezone = () => {
  const query = useQueryParams()
  const nativeTimezone = query.get('timezone')

  useEffect(() => {
    nativeTimezone && localStorage.setItem('timezone', nativeTimezone)
  }, [nativeTimezone])
}

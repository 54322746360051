import { format } from 'date-fns'
import { createApiClient } from 'services/axios'
import config from 'services/config'
import { configCatClient, FeatureFlag } from 'services/configCat'
import {
  getAppIdentifier,
  getDetectedLanguage,
  getUserTimeOffset,
  getUserTimeZone
} from 'services/helpers'
import { UserLoginEntity, UserSignUpEntity } from '../types'

async function createAuthApiClient() {
  const useApiV2 = await configCatClient.getValueAsync(
    FeatureFlag.useAccountApiV2,
    false
  )

  const baseURL = useApiV2 ? config.accountApiUrlV2 : config.accountApiUrl
  return createApiClient(baseURL)
}

export const login = async (props: UserLoginEntity) => {
  const locale = getDetectedLanguage()
  const appIdentifier = getAppIdentifier()
  const userTimezone = getUserTimeZone()
  const userTimezoneOffset = getUserTimeOffset()

  const AuthApiClient = await createAuthApiClient()
  const { data } = await AuthApiClient.post('/v3/login', {
    ...props,
    ...appIdentifier,
    userTimezone,
    userTimezoneOffset,
    locale
  })
  return data
}

export const signUp = async (props: UserSignUpEntity) => {
  const locale = getDetectedLanguage()
  const appIdentifier = getAppIdentifier()
  const userTimezone = getUserTimeZone()
  const userTimezoneOffset = getUserTimeOffset()

  const userEntityWithLocalData = {
    ...props,
    ...appIdentifier,
    userTimezone,
    userTimezoneOffset,
    userLocalDate: format(new Date(), 'yyyy-MM-dd'),
    userLocalTime: format(new Date(), 'HH:mm:ss'),
    locale
  }

  const AuthApiClient = await createAuthApiClient()
  const { data } = await AuthApiClient.post(
    '/v3/register',
    userEntityWithLocalData
  )
  return data
}

export const resetPassword = async (email: string) => {
  const locale = getDetectedLanguage()
  const appIdentifier = getAppIdentifier()
  const userTimezone = getUserTimeZone()
  const userTimezoneOffset = getUserTimeOffset()

  const AuthApiClient = await createAuthApiClient()
  const { data } = await AuthApiClient.post('password/reset', {
    ...appIdentifier,
    email: email.trim(),
    userTimezone,
    userTimezoneOffset,
    locale
  })
  return data
}

export const validate2faLogin = async (props: {
  username: string
  mfaCode: string
}) => {
  const appIdentifier = getAppIdentifier()
  const userTimezone = getUserTimeZone()
  const userTimezoneOffset = getUserTimeOffset()

  const AuthApiClient = await createAuthApiClient()
  const { data } = await AuthApiClient.post('/v3/two-factor', {
    ...props,
    ...appIdentifier,
    userTimezone,
    userTimezoneOffset
  })
  return data
}

export interface IValidTokenRes {
  result: string
}

export const validatePasswordResetToken = async (
  token: string
): Promise<IValidTokenRes> => {
  const AuthApiClient = await createAuthApiClient()
  const { data } = await AuthApiClient.get<IValidTokenRes>(
    `/v2/password/reset/${token}`
  )
  return data
}

export interface IPasswordResetRes {
  result: string
}

interface IPasswordResetReq {
  token: string
  newPassword: string
  repeatNewPassword: string
}

export const passwordReset = async ({
  token,
  ...payload
}: IPasswordResetReq): Promise<IPasswordResetRes> => {
  const AuthApiClient = await createAuthApiClient()
  const { data } = await AuthApiClient.post<IPasswordResetRes>(
    `/v2/password/reset/${token}`,
    payload
  )
  return data
}

export const errorTitles = [
  "We're having trouble logging you in, please try again. Username and/or password incorrect",
  'User exists',
  'Grant Token Error',
  'Code is not valid',
  'Cannot verify token',
  'Auth provider not found',
  "We're having trouble logging you in, please try again. Account locked due to too many attempts, please contact support",
  "Sorry, we couldn't reset your account password. Please contact us at hello@receiptjar.com",
  "Oops! It seems like there's a problem with creating your new account. Please try using a different email address or log in using the existing account associated with that email. If you still need help, please contact us at hello@receiptjar.com"
]

export const isKnownError = (errorMessage: string) =>
  !!errorTitles.find(i => i === errorMessage)

import makeAsyncScriptLoader from 'react-async-script'
import ReCAPTCHA from './component'

const callbackName = 'onloadcallback'
const globalName = 'grecaptcha'

const getURL = () =>
  `https://www.google.com/recaptcha/enterprise.js?onload=${callbackName}&render=explicit`

const asyncLoader = makeAsyncScriptLoader(getURL, {
  callbackName,
  globalName
})(ReCAPTCHA)

export default asyncLoader

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import Route from 'routes/routes'
import { AuthApi } from 'services/api'
import { useQueryParams } from 'services/custom-hooks'

export const useEmailLogin = (): [boolean, (props: any) => Promise<any>] => {
  const { t } = useTranslation('translation')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const redirectUrl: string = useQueryParams().get('redirect_url')

  const { mutateAsync } = useMutation(AuthApi.login)

  const handleLogin = async (props): Promise<void> => {
    setIsLoading(true)

    await mutateAsync(props, {
      onSuccess: async ({ result, code }) => {
        if (result === 'ok') {
          window.location.assign(redirectUrl + `?code=${code}`)
        } else if (result === '2fa') {
          history.push({
            pathname: Route.UNOCART_MFA_CODE,
            search: `?username=${encodeURIComponent(
              props.username
            )}&redirect_url=${redirectUrl}`
          })
        } else if (result === 'suspended') {
          history.push({
            pathname: Route.SUSPENDED
          })
        }
      },
      onError: async (error: any) => {
        setIsLoading(false)
        console.error('While logging in...', error)
        alert(
          `${t('signin.alert.failed')} ${t(
            error?.response?.data?.message || ''
          )}`
        )
        // await SegmentTrack('auth__fail__login', {
        //   errorMessage: error?.response?.data?.message
        // })
      }
    })
  }

  return [isLoading, handleLogin]
}

import { primaryColor } from 'assets/constants'
import React from 'react'

interface RootProps {
  children: React.ReactNode
  backgroundColor?: React.CSSProperties['backgroundColor']
}

const Root = ({ backgroundColor = primaryColor, children }: RootProps) => {
  return (
    <div className="min-vh-100 d-flex flex-column" style={{ backgroundColor }}>
      {children}
    </div>
  )
}

export default Root

import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'
import MainRoutesNavigator from 'routes'
import { initSentry } from 'services/sentry'

import './index.scss'

const queryClient = new QueryClient()

const browserHistory = createBrowserHistory()

initSentry(browserHistory)

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router history={browserHistory}>
      <MainRoutesNavigator />
    </Router>
  </QueryClientProvider>
)

export default Sentry.withErrorBoundary(App, {})

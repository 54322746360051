import StaticUrls, { StaticUrlsEnum } from 'assets/links'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import Route from 'routes/routes'
import { AuthApi } from 'services/api'
import { reverseIpLocation } from 'services/api/location'
import { queryKeys } from 'services/api/query-keys'
import { getDomain } from 'services/helpers'

export const useRelevantStaticUrl = () => {
  const { i18n } = useTranslation()
  const { language: rawLanguage } = i18n
  const domain = getDomain()
  const { data: countryCode } = useQuery([queryKeys.REVERSE_IP], () =>
    reverseIpLocation()
  )

  // Make Spanish links show for any Spanish dialect
  let language = rawLanguage
  if (rawLanguage.match(/^es\b/)?.[0]) language = 'es'

  const appropriateLink =
    StaticUrls[domain]?.[countryCode]?.[language] ||
    StaticUrls[domain]?.US?.en ||
    StaticUrls[domain]

  return (url: StaticUrlsEnum) => appropriateLink[url]
}

export const useEmailRegister = (): [
  boolean,
  (props: any) => Promise<any>,
  string,
  Dispatch<SetStateAction<string>>
] => {
  const history = useHistory()
  const { t } = useTranslation('translation')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { data: countryCode } = useQuery([queryKeys.REVERSE_IP], () =>
    reverseIpLocation()
  )
  const { mutateAsync } = useMutation(AuthApi.signUp)

  const handleRegister = async props => {
    setIsLoading(true)

    await mutateAsync(
      { ...props, countryCode },
      {
        onSuccess: async ({ result, code }) => {
          if (result === 'ok') {
            history.push({
              pathname: Route.CALLBACK,
              search: `?code=${code}&username=${props.email}&route=register`
            })
          }
        },
        onError: async (error: any) => {
          setIsLoading(false)
          console.error('register failed', error)
          setErrorMessage(
            error?.response?.data?.extra?.errors?.[0]?.msg
              ? t(error.response.data.extra.errors[0].msg)
              : t('register.alert.failed')
          )
          // await SegmentTrack('auth__fail__register', {
          //   errorMessage: message,
          //   email: props.email
          // })
        }
      }
    )
  }

  return [isLoading, handleRegister, errorMessage, setErrorMessage]
}

import { siteKey } from 'assets/constants'
import axios from 'axios'
import ReCaptcha from 'components/recaptcha-dialog/checkbox'
import React, { useRef, useState } from 'react'
import { Button, Container } from 'react-bootstrap'

const Debug = () => {
  const recaptchaRef = useRef(null)
  const [botScore, setBotScore] = useState(-2)
  const onSubmit = async () => {
    setBotScore(-3)
    const token = await recaptchaRef.current.execute()
    const { data } = await axios.post(
      'https://account.internal-api.unocart.com/v3/captcha',
      { token, siteKey }
    )
    setBotScore(data.score)
  }
  return (
    <Container>
      <h1>here</h1>
      <ReCaptcha ref={recaptchaRef} sitekey={siteKey} size={'invisible'} />
      <Button type="submit" onClick={onSubmit}>
        Login
      </Button>
      <div>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
      <h3>bot score: {botScore}</h3>
    </Container>
  )
}

export default Debug

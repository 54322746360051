import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { AuthApi } from 'services/api'

const useHandleResetPassword = (): [boolean, (props) => Promise<any>] => {
  const history = useHistory()
  const { t } = useTranslation('translation', {
    keyPrefix: 'resetPassword.alert'
  })
  const { isLoading, mutateAsync } = useMutation(AuthApi.resetPassword)

  const handleResetPassword = async ({ email }) =>
    await mutateAsync(email, {
      onSuccess: async () => {
        alert(t('success'))
        history.goBack()
      },
      onError: () => {
        console.error('reset password errored', email)
        alert(t('failed'))
      }
    })

  return [isLoading, handleResetPassword]
}

export default useHandleResetPassword

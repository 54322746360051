import { useTranslation } from 'react-i18next'
import { AuthApi } from 'services/api'
import { useQueryParams } from 'services/custom-hooks'

export const useVerifyAuthCode = () => {
  const { t } = useTranslation('translation')
  const query = useQueryParams()

  const username = query.get('username')
  const redirectUrl = query.get('redirect_url')

  return async (mfaCode): Promise<void> => {
    try {
      const { result, code } = await AuthApi.validate2faLogin({
        username,
        mfaCode
      })
      if (result === 'ok') {
        window.location.assign(redirectUrl + `?code=${code}`)
      }
    } catch (error) {
      console.error('2fa error', error)
      alert(
        error?.response?.data?.message
          ? t(error.response.data.message)
          : t('mfaCode.alert.failed')
      )
    }
  }
}

import { Container, Header, Root, Title } from 'components'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Suspended = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'suspended' })

  return (
    <Root>
      <Header />
      <Container>
        <Title text={t('title')} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: 60, height: 60 }}
          />
        </div>
      </Container>
    </Root>
  )
}

export default Suspended

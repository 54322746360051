import React from 'react'

interface TitleProps {
  text: string
  subText?: string
}

const Title = ({ text, subText }: TitleProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '1rem 0'
      }}
    >
      <h1 className="h2 text-center pt-2">
        <strong>{text}</strong>
      </h1>
      {subText && <p className="pt-2 text-muted text-center">{subText}</p>}
    </div>
  )
}

export default Title

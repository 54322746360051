import PasswordResetForm from 'pages/password-reset/components/form'
import InvalidPasswordResetToken from 'pages/password-reset/components/invalid-token'
import { useRegisterPasswordResetToken } from 'pages/password-reset/hooks'
import React from 'react'

interface Props {
  variant?: 'primary' | 'unocart'
}

const PasswordResetContent = ({ variant }: Props) => {
  const [token, data, isLoadingToken] = useRegisterPasswordResetToken()

  if (token && isLoadingToken) {
    return (
      <div className="w-100 d-flex justify-content-center py-4">
        <span className="spinner-border spinner-border-sm" />
      </div>
    )
  }

  if (data?.result === 'ok') {
    return <PasswordResetForm token={token} variant={variant} />
  }

  return <InvalidPasswordResetToken />
}

export default PasswordResetContent

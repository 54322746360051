import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import { BsApple, BsFacebook } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

interface ButtonProps {
  variant: 'apple' | 'facebook'
}

const Button = ({ variant }: ButtonProps) => {
  const history = useHistory()
  const { t } = useTranslation('translation', {
    keyPrefix: 'externalAuthButtons'
  })

  const handleClick = () =>
    history.push({
      pathname: window.location.pathname,
      search: `?auth=${variant}`
    })

  return (
    <BootstrapButton
      variant={variant}
      size="lg"
      onClick={handleClick}
      style={{
        width: '100%',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.8rem'
      }}
    >
      {variant === 'apple' ? <BsApple /> : <BsFacebook />} {t(variant)}
    </BootstrapButton>
  )
}

export default Button

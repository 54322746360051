import axios from 'axios'
import config from 'services/config'

const LocationApiClient = axios.create({
  baseURL: config.apiUrl + '/location'
})

export const reverseIpLocation = async () => {
  const { data } = await LocationApiClient.get('/reverse-ip')
  return data?.countryCode
}

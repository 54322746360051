import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useVerifyAuthCode } from './hooks'

const UnocartMFACodeForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'mfaCode' })

  const handleVerify = useVerifyAuthCode()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = async ({ code }) => handleVerify(code)

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="verificationCode">
        <Controller
          control={control}
          name="code"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('errorMessages.code.required')
            }
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.code}
              placeholder={t('placeholders.code')}
              size="lg"
              autoCapitalize="off"
              autoCorrect="none"
              inputMode="numeric"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="UnocartMFACode.Code.Errors"
        >
          {errors.code?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Controller
        name="submitButton"
        control={control}
        render={({ formState }) => (
          <Button
            type="submit"
            variant="unocart"
            size="lg"
            style={{
              width: '100%',
              color: '#fff'
            }}
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span className="spinner-border spinner-border-sm mr-1" />
            ) : (
              t('submitButton')
            )}
          </Button>
        )}
      />
    </Form>
  )
}

export default UnocartMFACodeForm

import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router'
import Route from 'routes/routes'

export const RegisterLink = () => {
  const history = useHistory()

  const handleClick = () => history.push(Route.REGISTER)

  return (
    <div
      className="text-muted"
      style={{ marginTop: '1.5rem', textAlign: 'center' }}
    >
      <Trans i18nKey="footers.register">
        Don't have an account?{' '}
        <strong onClick={handleClick} className="link-primary">
          Create Account
        </strong>
      </Trans>
    </div>
  )
}

interface SignInLinkProps {
  className?: string
  route?: string
  withParams?: string
}

export const SignInLink = ({
  className,
  route,
  withParams = null
}: SignInLinkProps) => {
  const history = useHistory()

  const handleClick = () =>
    history.push({
      pathname: route || Route.LOGIN,
      search: withParams
    })

  return (
    <div
      className="text-muted"
      style={{ marginTop: '1.5rem', textAlign: 'center' }}
    >
      <Trans i18nKey="footers.signin">
        Already have an account?{' '}
        <strong onClick={handleClick} className={className || 'link-primary'}>
          Sign in
        </strong>
      </Trans>
    </div>
  )
}

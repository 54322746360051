const Route = {
  LOGIN: '/signin',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password',
  PASSWORD_RESET: '/account/password/reset',
  CALLBACK: '/callback',
  DEBUG: '/debug',
  MFA_CODE: '/mfa-code',
  SUSPENDED: '/suspended',
  REGISTER_BLOCKED: '/register-blocked',
  UNOCART_LOGIN: '/unocart-signin',
  UNOCART_RESET_PASSWORD: '/unocart-reset-password',
  UNOCART_PASSWORD_RESET: '/unocart-password-reset',
  UNOCART_MFA_CODE: '/unocart-mfa-code'
} as const

export default Route

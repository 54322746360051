import { StaticUrlsEnum } from 'assets/links/types'

const StaticUrls = {
  receiptjar: {
    CA: {
      'fr-CA': {
        [StaticUrlsEnum.TERMS]: 'https://receiptjar.com/terms-conditions-ca-fr',
        [StaticUrlsEnum.PRIVACY]: 'https://receiptjar.com/privacy-policy-ca-fr'
      }
    },
    US: {
      en: {
        [StaticUrlsEnum.TERMS]: 'https://receiptjar.com/terms-conditions',
        [StaticUrlsEnum.PRIVACY]: 'https://receiptjar.com/privacy-policy',
        [StaticUrlsEnum.WAITING_LIST]: 'https://www.olanota.com/lista-de-espera' // No English version provided
      },
      es: {
        [StaticUrlsEnum.TERMS]: 'https://receiptjar.com/terms-conditions-es-us',
        [StaticUrlsEnum.PRIVACY]: 'https://receiptjar.com/privacy-policy-es-us'
      }
    }
  },
  olanota: {
    [StaticUrlsEnum.TERMS]: 'https://www.olanota.com/termos-e-condies',
    [StaticUrlsEnum.PRIVACY]: 'https://www.olanota.com/poltica-de-privacidade',
    [StaticUrlsEnum.WAITING_LIST]: 'https://www.olanota.com/lista-de-espera'
  }
}

export { StaticUrlsEnum } from './types'

export default StaticUrls

import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Route from 'routes/routes'
import { AuthApi } from 'services/api'
import { useQueryParams } from 'services/custom-hooks'

export const useVerifyAuthCode = (): [
  (mfaCode: any) => Promise<void>,
  string,
  Dispatch<SetStateAction<string>>
] => {
  const history = useHistory()
  const { t } = useTranslation('translation')
  const query = useQueryParams()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const username = query.get('username')

  const handleVerify = async (mfaCode): Promise<void> => {
    try {
      const { result, code } = await AuthApi.validate2faLogin({
        username,
        mfaCode
      })
      if (result === 'ok') {
        history.push({
          pathname: Route.CALLBACK,
          search: `?code=${code}&username=${username}&route=signin`
        })
      }
    } catch (error) {
      console.error('2fa error', error)
      setErrorMessage(
        error?.response?.data?.message
          ? t(error.response.data.message)
          : t('mfaCode.alert.failed')
      )
    }
  }

  return [handleVerify, errorMessage, setErrorMessage]
}

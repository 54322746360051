import {
  CallbackPage,
  DebugPage,
  LoginPage,
  MFACode,
  RegisterBlockedPage,
  RegisterPage,
  ResetPasswordPage,
  SuspendedPage,
  UnocartMFACode,
  UnocartResetPassword,
  UnocartSigninPage
} from 'pages'
import PasswordReset from 'pages/password-reset'
import React from 'react'
import {
  useRegisterAppIdentifier,
  useRegisterAppTimezone
} from 'services/custom-hooks'
import { SentryRoute } from 'services/sentry'
import Route from './routes'

const MainRoutesNavigator = () => {
  useRegisterAppIdentifier()
  useRegisterAppTimezone()

  // Ignore me: Empty commit
  return (
    <>
      <SentryRoute exact path={Route.LOGIN} component={LoginPage} />
      <SentryRoute exact path={Route.REGISTER} component={RegisterPage} />
      <SentryRoute
        exact
        path={Route.RESET_PASSWORD}
        component={ResetPasswordPage}
      />
      <SentryRoute
        exact
        path={Route.PASSWORD_RESET}
        component={PasswordReset.App}
      />
      <SentryRoute exact path={Route.MFA_CODE} component={MFACode} />
      <SentryRoute exact path={Route.CALLBACK} component={CallbackPage} />
      <SentryRoute exact path={Route.DEBUG} component={DebugPage} />
      <SentryRoute exact path={Route.SUSPENDED} component={SuspendedPage} />
      <SentryRoute
        exact
        path={Route.REGISTER_BLOCKED}
        component={RegisterBlockedPage}
      />
      <SentryRoute
        exact
        path={Route.UNOCART_LOGIN}
        component={UnocartSigninPage}
      />
      <SentryRoute
        exact
        path={Route.UNOCART_RESET_PASSWORD}
        component={UnocartResetPassword}
      />
      <SentryRoute
        exact
        path={Route.UNOCART_PASSWORD_RESET}
        component={PasswordReset.Unocart}
      />
      <SentryRoute
        exact
        path={Route.UNOCART_MFA_CODE}
        component={UnocartMFACode}
      />
    </>
  )
}

export default MainRoutesNavigator

import { siteKey } from 'assets/constants'
import { StaticUrlsEnum } from 'assets/links'
import { ReCaptchaDialog } from 'components'
import CustomModal from 'components/modal'
import { escapeRegExp } from 'lodash'
import { isKnownError } from 'pages/helpers'
import React, { SyntheticEvent, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  customValidator,
  emailPatternRule,
  englishOnlyRule,
  FormValidationSchema,
  nonEmojisRule
} from 'services/form-validation'
import { useEmailRegister, useRelevantStaticUrl } from './hooks'

const RegisterForm = () => {
  const password = useRef<string>('')
  const { t } = useTranslation('translation', { keyPrefix: 'register' })
  const [isLoading, handleRegister, errorMessage, setErrorMessage] =
    useEmailRegister()
  const [showReCaptcha, setShowReCaptcha] = useState<boolean>(false)
  const getRelevantStaticUrl = useRelevantStaticUrl()

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    trigger
  } = useForm({
    resolver: data => {
      const errors = customValidator(validationSchema, data)

      return {
        values: data,
        errors
      }
    }
  })

  password.current = watch('password', '')

  const validationSchema: FormValidationSchema = {
    firstName: {
      pattern: [
        {
          message: t('errorMessages.firstName.englishOnly'),
          value: englishOnlyRule
        }
      ],
      required: {
        value: true,
        message: t('errorMessages.firstName.required')
      }
    },
    lastName: {
      pattern: [
        {
          message: t('errorMessages.lastName.englishOnly'),
          value: englishOnlyRule
        }
      ],
      required: {
        value: true,
        message: t('errorMessages.lastName.required')
      }
    },
    email: {
      pattern: [
        {
          message: t('errorMessages.email.pattern'),
          value: nonEmojisRule
        },
        {
          value: emailPatternRule,
          message: t('errorMessages.email.pattern')
        }
      ],
      required: {
        value: true,
        message: t('errorMessages.email.required')
      }
    },
    password: {
      pattern: [
        {
          value: /(?=.*\d)(?=.*[a-zA-Z])/,
          message: t('errorMessages.password.pattern')
        }
      ],
      required: {
        value: true,
        message: t('errorMessages.password.required')
      },
      minLength: {
        value: 8,
        message: t('errorMessages.password.minLength')
      },
      maxLength: {
        value: 100,
        message: t('errorMessages.password.maxLength')
      }
    },
    confirmationPassword: {
      pattern: [
        {
          value: new RegExp(`^${escapeRegExp(password.current)}$`),
          message: t('errorMessages.confirmPassword.pattern')
        }
      ],
      required: {
        value: true,
        message: t('errorMessages.confirmPassword.required')
      }
    }
  }

  const handleShowReCaptchaDialog = async (e: SyntheticEvent) => {
    e.preventDefault()
    const isValid = await trigger()
    if (isValid) setShowReCaptcha(true)
  }

  const handleReCaptchaSuccess = (token: string) => {
    setShowReCaptcha(false)
    const { confirmationPassword, email, firstName, lastName, password } =
      getValues()

    return handleRegister({
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      password,
      confirmationPassword,
      siteKey,
      token
    })
  }

  return (
    <Form noValidate onSubmit={handleShowReCaptchaDialog}>
      <Form.Group className="mb-3" controlId="firstName">
        <Controller
          control={control}
          name="firstName"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.firstName}
              placeholder={t('placeholders.firstName')}
              size="lg"
              autoCapitalize="words"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="Register.FirstName.Errors"
        >
          {errors.firstName?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="lastName">
        <Controller
          control={control}
          name="lastName"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.lastName}
              placeholder={t('placeholders.lastName')}
              size="lg"
              autoCapitalize="words"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="Register.LastName.Errors"
        >
          {errors.lastName?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="registerEmail">
        <Controller
          control={control}
          name="email"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.email}
              placeholder={t('placeholders.email')}
              size="lg"
              autoCapitalize="off"
              autoCorrect="none"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="Register.Email.Errors"
        >
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="registerPassword">
        <Controller
          control={control}
          name="password"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.password}
              placeholder={t('placeholders.password')}
              size="lg"
              type="password"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="Register.Password.Errors"
        >
          {errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="registerConfirmationPassword">
        <Controller
          control={control}
          name="confirmationPassword"
          defaultValue=""
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.confirmationPassword}
              placeholder={t('placeholders.confirmPassword')}
              size="lg"
              type="password"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="Register.ConfirmPassword.Errors"
        >
          {errors.confirmationPassword?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Controller
        name="submitButton"
        control={control}
        render={({ formState }) => (
          <Button
            type="submit"
            variant="primary"
            size="lg"
            style={{
              width: '100%',
              color: '#fff'
            }}
            disabled={formState.isSubmitting || isLoading}
          >
            {formState.isSubmitting || isLoading ? (
              <span className="spinner-border spinner-border-sm mr-1" />
            ) : (
              t('submitButton')
            )}
          </Button>
        )}
      />
      <div
        className="pt-2 px-3 text-center text-muted"
        style={{ fontSize: '0.8rem' }}
      >
        {t('termsAndConditions.part0')}{' '}
        <a
          href={getRelevantStaticUrl(StaticUrlsEnum.TERMS)}
          className="link-primary"
        >
          {t('termsAndConditions.part1')}
        </a>{' '}
        {t('termsAndConditions.part2')}{' '}
        <a
          href={getRelevantStaticUrl(StaticUrlsEnum.PRIVACY)}
          className="link-primary"
        >
          {t('termsAndConditions.part3')}
        </a>
      </div>
      <ReCaptchaDialog
        show={showReCaptcha}
        handleClose={() => setShowReCaptcha(false)}
        handleSuccess={handleReCaptchaSuccess}
      />
      <CustomModal
        show={!!errorMessage.length}
        title={
          isKnownError(errorMessage)
            ? t(`errorTitle.${errorMessage}`)
            : t('errorTitle.defaultErrorTitle')
        }
        message={errorMessage}
        confirmButtonProps={{
          text: t('alert.confirmButton'),
          onClick: () => setErrorMessage('')
        }}
      />
    </Form>
  )
}

export default RegisterForm

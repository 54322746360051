import { isArray } from 'lodash'

/** @deprecated
 * Old rule that allow creating invalid account
 * Please don't use this rule except login
 * */
const loginPatternRule = /(.+)@(.+)\.(.{2,})/

const emailPatternRule = /^\S+@\S+\.([A-Za-z]{2,}[\s?]*)$/

const nonEmojisRule = /^[^\p{Extended_Pictographic}]*$/gu

const englishOnlyRule = /^[a-zA-Z\s][a-zA-Z\s\-]*[a-zA-Z\s]$|^[a-zA-Z]$/

export type FormValidationSchema = Record<
  string,
  Partial<
    Record<
      'required' | 'pattern' | 'minLength' | 'maxLength',
      Record<'value' | 'message', any> | Record<'value' | 'message', any>[]
    >
  >
>

type DataType = Record<string, any>

const customValidator = (schema: FormValidationSchema, data: DataType) => {
  let errors = {}

  Object.entries(schema).forEach(([fieldName, rules]) => {
    const fieldValue = data?.[fieldName]

    Object.entries(rules).forEach(([ruleName, ruleProps]) => {
      if (ruleName === 'pattern') {
        const errorMessage =
          isArray(ruleProps) &&
          ruleProps.reduce((acc, curr) => {
            if (!fieldValue.match(curr.value)) {
              return curr.message
            }
            return acc
          }, '')
        if (errorMessage) {
          errors = {
            ...errors,
            [fieldName]: {
              message: errorMessage
            }
          }
        }
      }

      if (ruleName === 'minLength') {
        if (
          fieldValue?.length &&
          !isArray(ruleProps) &&
          fieldValue.length < ruleProps.value
        ) {
          errors = {
            ...errors,
            [fieldName]: {
              message: ruleProps.message
            }
          }
        }
      }

      if (ruleName === 'maxLength') {
        if (
          fieldValue?.length &&
          !isArray(ruleProps) &&
          fieldValue.length > ruleProps.value
        ) {
          errors = {
            ...errors,
            [fieldName]: {
              message: ruleProps.message
            }
          }
        }
      }

      if (ruleName === 'required') {
        if (!isArray(ruleProps) && ruleProps.value === true) {
          if (!fieldValue?.length) {
            errors = {
              ...errors,
              [fieldName]: {
                message: ruleProps.message
              }
            }
          }
        }
      }
    })
  })

  return errors
}

export {
  customValidator,
  emailPatternRule,
  englishOnlyRule,
  loginPatternRule,
  nonEmojisRule
}

import { Container, Header, RegisterLink, Root, SignInLink } from 'components'
import PasswordResetContent from 'pages/password-reset/content'
import React from 'react'
import { isWebview } from 'services/helpers'

const App = () => {
  const isUIWebview = isWebview()
  return (
    <Root>
      <Header />
      <Container>
        <PasswordResetContent />
        {isUIWebview && (
          <>
            <SignInLink />
            <RegisterLink />
          </>
        )}
      </Container>
    </Root>
  )
}

const Unocart = () => {
  return (
    <Root backgroundColor="#fff">
      <Header img="unocart" />
      <Container>
        <PasswordResetContent variant="unocart" />
      </Container>
    </Root>
  )
}

const PasswordReset = ({ children }) => children

PasswordReset.App = App
PasswordReset.Unocart = Unocart

export default PasswordReset

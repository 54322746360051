import { BlockedAssets } from 'assets/blocked'
import { primaryColor } from 'assets/constants'
import { Container, Header, Root, SignInLink, Title } from 'components'
import React from 'react'
import { Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const RegisterBlocked = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'register-blocked' })

  return (
    <Root>
      <Header />
      <Container>
        <Title text={t('title')} />
        <div
          style={{
            position: 'relative',
            left: 12,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Image
            src={BlockedAssets.Register}
            style={{
              height: 'auto',
              width: 150,
              marginTop: 16,
              marginBottom: 32
            }}
          />
        </div>
        <div style={{ textAlign: 'center', marginBottom: 36 }}>
          <p style={{ color: primaryColor, fontWeight: 'bold', fontSize: 20 }}>
            {t('0')}
          </p>
          <p>{t('1')}</p>
        </div>
        <SignInLink />
      </Container>
    </Root>
  )
}

export default RegisterBlocked

import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import Route from 'routes/routes'
import { AuthApi } from 'services/api'

export const useEmailLogin = (): [
  boolean,
  (props: any) => Promise<any>,
  string,
  Dispatch<SetStateAction<string>>
] => {
  const { t } = useTranslation('translation')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const history = useHistory()

  const { mutateAsync } = useMutation(AuthApi.login)

  const handleLogin = async (props): Promise<void> => {
    setIsLoading(true)

    await mutateAsync(props, {
      onSuccess: async ({ result, code }) => {
        if (result === 'ok') {
          history.push({
            pathname: Route.CALLBACK,
            search: `?code=${code}&username=${props.username}&route=signin`
          })
        } else if (result === '2fa') {
          history.push({
            pathname: Route.MFA_CODE,
            search: `?username=${encodeURIComponent(props.username)}`
          })
        } else if (result === 'suspended') {
          history.push({
            pathname: Route.SUSPENDED,
            search: `?username=${encodeURIComponent(props.username)}`
          })
        }
      },
      onError: async (error: any) => {
        setIsLoading(false)
        console.error('While logging in....', error)
        setErrorMessage(
          `${t('signin.alert.failed')} ${t(
            error?.response?.data?.message || ''
          )}`
        )
      }
    })
  }

  return [isLoading, handleLogin, errorMessage, setErrorMessage]
}

import { Container, Header, Root, SignInLink, Title } from 'components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Route from 'routes/routes'
import { useQueryParams } from 'services/custom-hooks'
import UnocartResetPasswordForm from './form'

const UnocartResetPassword = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'resetPassword' })
  const redirectUrl = useQueryParams().get('redirect_url')

  return (
    <Root backgroundColor="#fff">
      <Header img="unocart" />
      <Container style={{ maxWidth: 500 }}>
        <Title text={t('title')} subText={t('subtitle')} />
        <UnocartResetPasswordForm />
        <SignInLink
          className="link link-unocart"
          route={Route.UNOCART_LOGIN}
          withParams={`?redirect_url=${redirectUrl}`}
        />
      </Container>
    </Root>
  )
}

export default UnocartResetPassword

import { siteKey } from 'assets/constants'
import { ReCaptchaDialog } from 'components'
import React, { SyntheticEvent, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Route from 'routes/routes'
import { useQueryParams } from 'services/custom-hooks'
import { loginPatternRule } from 'services/form-validation'
import { useEmailLogin } from './hooks'

const UnocartLoginForm = () => {
  const history = useHistory()
  const { t } = useTranslation('translation', { keyPrefix: 'signin' })
  const [isLoading, handleLogin] = useEmailLogin()
  const [showReCaptcha, setShowReCaptcha] = useState<boolean>(false)
  const redirectUrl = useQueryParams().get('redirect_url')

  const {
    control,
    formState: { errors },
    getValues,
    trigger
  } = useForm()

  const handleShowReCaptchaDialog = async (e: SyntheticEvent) => {
    e.preventDefault()
    const isValid = await trigger()
    if (isValid) setShowReCaptcha(true)
  }

  const handleReCaptchaSuccess = (token: string) => {
    setShowReCaptcha(false)
    const { email: username, password } = getValues()
    return handleLogin({ username, password, siteKey, token })
  }

  const handleForgotPasswordClick = () =>
    history.push(Route.UNOCART_RESET_PASSWORD + `?redirect_url=${redirectUrl}`)

  return (
    <Form noValidate onSubmit={handleShowReCaptchaDialog}>
      <Form.Group className="mb-3" controlId="loginEmail">
        <Controller
          control={control}
          name="email"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('errorMessages.email.required')
            },
            pattern: {
              value: loginPatternRule,
              message: t('errorMessages.email.pattern')
            }
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.email}
              placeholder={t('placeholders.email')}
              size="lg"
              autoCapitalize="off"
              autoCorrect="none"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="UnocartSignin.Email.Errors"
        >
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="loginPassword">
        <Controller
          control={control}
          name="password"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t('errorMessages.password.required')
            },
            minLength: {
              value: 8,
              message: t('errorMessages.password.minLength')
            },
            maxLength: {
              value: 100,
              message: t('errorMessages.password.maxLength')
            },
            pattern: {
              value: /(?=.*\d)(?=.*[a-zA-Z])/,
              message: t('errorMessages.password.pattern')
            }
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors.password}
              placeholder={t('placeholders.password')}
              size="lg"
              type="password"
            />
          )}
        />
        <Form.Control.Feedback
          type="invalid"
          data-testid="UnocartSignin.Password.Errors"
        >
          {errors.password?.message}
        </Form.Control.Feedback>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Form.Text
            className="link text-bold"
            onClick={handleForgotPasswordClick}
          >
            {t('forgotPassword')}
          </Form.Text>
        </div>
      </Form.Group>
      <Controller
        name="submitButton"
        control={control}
        render={({ formState }) => (
          <Button
            type="submit"
            variant="unocart"
            size="lg"
            style={{
              width: '100%',
              color: '#fff'
            }}
            disabled={formState.isSubmitting || isLoading}
          >
            {formState.isSubmitting || isLoading ? (
              <span className="spinner-border spinner-border-sm mr-1" />
            ) : (
              t('submitButton')
            )}
          </Button>
        )}
      />
      <ReCaptchaDialog
        show={showReCaptcha}
        handleClose={() => setShowReCaptcha(false)}
        handleSuccess={handleReCaptchaSuccess}
      />
    </Form>
  )
}

export default UnocartLoginForm
